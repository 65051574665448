import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body d-flex flex-column p-0" }
const _hoisted_2 = { class: "d-flex flex-stack flex-grow-1 card-p" }
const _hoisted_3 = { class: "d-flex flex-column me-2" }
const _hoisted_4 = {
  href: "javascript:void(0)",
  class: "text-dark text-hover-primary fw-bolder fs-3"
}
const _hoisted_5 = { class: "text-muted fw-bold mt-1" }
const _hoisted_6 = { class: "symbol symbol-50px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("a", _hoisted_4, _toDisplayString(_ctx.title), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.description), 1)
        ]),
        _createElementVNode("span", _hoisted_6, [
          _createElementVNode("span", {
            class: _normalizeClass([`bg-light-${_ctx.color} text-${_ctx.color}`, "symbol-label fs-5 fw-bolder"])
          }, _toDisplayString(_ctx.change), 3)
        ])
      ]),
      _createVNode(_component_apexchart, {
        class: "statistics-widget-3-chart card-rounded-bottom",
        options: _ctx.chartOptions,
        series: _ctx.series,
        height: _ctx.height,
        type: "area"
      }, null, 8, ["options", "series", "height"])
    ])
  ], 2))
}