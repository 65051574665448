import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body d-flex align-items-center pt-3 pb-0" }
const _hoisted_2 = { class: "d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2" }
const _hoisted_3 = {
  href: "javascript:void(0)",
  class: "fw-bolder text-dark fs-4 mb-2 text-hover-primary"
}
const _hoisted_4 = { class: "fw-bold text-muted fs-5" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("a", _hoisted_3, _toDisplayString(_ctx.title), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.description), 1)
      ]),
      _createElementVNode("img", {
        src: _ctx.avatar,
        alt: "",
        class: "align-self-end h-100px"
      }, null, 8, _hoisted_5)
    ])
  ], 2))
}